import React from "react"
import styled from "styled-components"
import Img from 'gatsby-image'
import { Link } from "gatsby"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"

const Card = styled.div`
	margin-bottom:30px;
	display:flex;
	flex-wrap: wrap;
	align-items:center;
	background: #F5F5F5;
	
`
const CardTitle = styled.h3`
	margin:0 0 10px;
	color:#020304;
	font-size:28px;
	line-height:44px;

`
const CardFigure = styled(Link)`
	position:relative;
	width: 100%;
	height: 352px;
	& .gatsby-image-wrapper{
		height:100%;
	}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height:25%;
		background: -moz-linear-gradient(top,  rgba(246,246,246,1) 0%, rgba(246,246,246,0.7) 50%,  rgba(246,246,246,0) 100%);
		background: -webkit-linear-gradient(top,  rgba(246,246,246,1) 0%, rgba(246,246,246,0.7) 50%, rgba(246,246,246,0) 100%);
		background: linear-gradient(to bottom,  rgba(246,246,246,1) 0%, rgba(246,246,246,0.7) 50%, rgba(246,246,246,0) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F6F6F6', endColorstr='#00fffced',GradientType=1 );
	}
	${BreakpointUp.md`
		width: 50%;	
		&:after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 25%;
			height: 100%;
			background: -moz-linear-gradient(left,  rgba(246,246,246,1) 0%, rgba(246,246,246,0.7) 25%,  rgba(246,246,246,0) 100%);
			background: -webkit-linear-gradient(left,  rgba(246,246,246,1) 0%, rgba(246,246,246,0.7) 25%, rgba(246,246,246,0) 100%);
			background: linear-gradient(to right,  rgba(246,246,246,1) 0%, rgba(246,246,246,0.7) 25%, rgba(246,246,246,0) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F6F6F6', endColorstr='#00fffced',GradientType=1 );
		}
	`}
`
const CardContent = styled.div`	
	position:relative;
	z-index:1;
	width: 100%;
	padding:30px;
	& a {
		color: #020304;
		& svg{
			fill:#020304;
			transition: transform 0.3s ease-out;
		}
		&:hover{
			color:#020304;
			&:after {
				transform-origin: left center;        
				transform: translateZ(0) scaleX(1);
			}  
			svg{
				fill:#020304;
				transform: translateX(4px);
			}			
		}			
	}
	${BreakpointUp.md`
		padding:50px;
		width: 50%;
	`}
`
const CardDescription = styled.p`
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	overflow: hidden;
	svg{
		vertical-align: text-bottom;
	}
`

const CategoryCard = ({ data }) => {
	return(						
    <Card className="card">
      <CardContent className="card-body">
        <CardTitle>{data.name}</CardTitle>
        <CardDescription>{data.description.description}<Link to={data.link}><ArrowIcon /></Link></CardDescription>
        <Link to={data.link}>
        	<span className="text">Explore</span> <ArrowIcon />
        </Link>
      </CardContent>
      {data.categoryImage && (
      	<CardFigure to={data.link} className="card-img">
	        <Img fluid={data.categoryImage.fluid} />
	      </CardFigure>
      )}
    </Card>
	)
}


export default CategoryCard