import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { v4 } from "uuid"
import { Section, SectionTitle, SectionDescription } from "../../components/section"
import ArticleCard from "../../components/ArticleList"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import BreakpointUp from "../../components/Media/BreakpointUp"

const ArticleGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto;
    grid-column-gap: 30px;
    ${BreakpointUp.lg`				
      padding:0 30px;
      grid-template-columns: repeat(3, 1fr);
		`}
  }
`
const ReadMore = styled(Link)`  
  display: none;
  	${BreakpointUp.lg`
		display:block;	
		position:absolute;
		left:${props => props.left};
		right:${props => props.right};
		top:50%;
		transform: rotate(-90deg)translateY(-50%);
		z-index: 1;
		color:#020304;
		font-weight:700;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			height: 2px;
			transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transition: transform .4s cubic-bezier(.77,0,.175,1);
			transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transform: translateZ(0) scaleX(1);
			background: #020304;
			transform-origin: right center;    
			transform: translateZ(0) scaleX(0);        
		}
		& .text{
			+ & svg{
			margin-left:5px;
			}
		}
		& svg{
			fill:#020304;
			transition: transform 0.3s ease-out;
		}
		&:hover{
			color:#020304;
			&:after {
				transform-origin: left center;        
				transform: translateZ(0) scaleX(1);
			}  
			svg{
				fill:#020304;
				transform: translateX(4px);
			}			
		}						
			
	`}
`

const ArticleSection = ({ sectionData, articles }) => {
	return(
		<Section pt="90px" pb="90px" xpt="60px" xpb="60px" bg="linear-gradient(to bottom,#ffffff 0%,#ffffff 50%, #f6f6f6 100%)">			
			<ReadMore to={sectionData.sectionButtonLink} right="-70px"><span className="text">{sectionData.sectionButtonText}</span><ArrowIcon/></ReadMore>
			<div className="container">
        <SectionTitle maxWidth="650px" mb="10px">{sectionData.sectionTitle}<br />{sectionData.subTitle}</SectionTitle>	
        <SectionDescription mb="30px">{sectionData.sectionDescription.sectionDescription}</SectionDescription>	
				<ArticleGrid>
					{
						articles.edges.map((item, i)=>{
							if ( i < 3) {
								return(
									<ArticleCard key={v4()} data={item.node} />
								)
							}
							return true
						})
					}
				</ArticleGrid>
			</div>
		</Section>
	)
}
export default ArticleSection