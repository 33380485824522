import React, { Component } from 'react'
import { v4 } from 'uuid'
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "../ProductCard"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import LeftArrowIcon from "../../components/Icons/LeftArrowIcon"

const SlickItem = styled.div`
  display:block !important;
  outline:none;
  max-width:100%;
  text-align: left;
  padding: 10px 40px;
  @media(min-width: 1200px) {
  padding: 10px 30px;
  }
  @media(min-width: 1600px) {
    padding: 10px 50px;
  }
  .gatsby-image-wrapper {
    min-height: 200px;
  }
  @media(max-width: 767px) {
    padding: 10px 40px 10px 10px;
  }
  @media(max-width: 500px) {
    padding: 20px;
  }
  .card-body{
    @media(min-width: 992px) {
      width:50%;
    }
    @media(min-width: 1200px) {      
      width:45%;
    }
  }
  .card-img{
    @media(min-width: 992px) {
      width:50%;
    }
    @media(min-width: 1200px) {
      width:55%;
    }
  }
`
const SliderContent = styled.div`
  .slick-arrow{   
    top:25%;
  }
  .slick-prev{
    left:-15px;
  }
  .slick-next{
    right:-15px;
  }
  @media(max-width: 500px) {
    .slick-list {
      padding: 0 !important;
    }
  }
`


const RightArrow = styled.div`
  display: none;
  position: absolute;
  top: 450px;
  right: 0px;
  width: 50px;
  height: 50px;
  padding: 12px 5px;
  border-radius: 50px;
  background: white;
  border: 1px solid #ddd; 
  z-index: 10;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all .3s ease;
      transform: translateX(5px);
    }
  }
  @media(max-width: 767px) {
    display: block;
  }
`
const LeftArrow = styled.div`
  display: none;
  position: absolute;
  top: 450px;
  left: 0px;
  width: 50px;
  height: 50px;
  padding: 12px 5px;
  border-radius: 50px;
  border: 1px solid #ddd; 
  background: white;
  z-index: 10;
  & :hover {
    cursor: pointer;
    & svg {
      transition: all .3s ease-in-out;
      transform: translateX(-5px);
    }
  }
  @media(max-width: 767px) {
    display: block;
  }
`


function CircleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowIcon/></button>
  );
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={className}
      style={{ ...style, display: "flex"}}
      onClick={onClick}
    ><ArrowIcon/></button>
  );
}

export default class FeaturedProductCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
  }
  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }
  render() {
    const settings = {
      centerMode: true,
      centerPadding: '94px',
      slidesToShow: 1,
      infinite: true,      
      slidesToScroll: 1,
      speed: 300,
      arrows: false,
      responsive: [     
        {
          breakpoint: 767,
          settings: {
            centerMode: true,
            centerPadding: '5px',
          }
        },
        {
          breakpoint: 500,
          settings: {
            centerMode: true,
            centerPadding: '0',
          }
        }
      ]      
    };
    const { data } = this.props
    return (    
      <SliderContent>     
        <Slider {...settings} ref={c => (this.slider = c)}>
          {
            data.map((item, i) => {
              return(
                <SlickItem key={v4()}>
                  <ProductCard data={item.node} showQuote={this.props.showQuote}  />
                </SlickItem>
              )
            })
          }
         
        </Slider>
        <LeftArrow onClick={this.previous}>
           <LeftArrowIcon fill="#020304" />
        </LeftArrow>
        <RightArrow onClick={this.next}>
          <ArrowIcon fill="#020304" />
        </RightArrow>
      </SliderContent>
    );
  }
}
