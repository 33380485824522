import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { Section, SectionTitle } from "../../components/section"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import ListArrow from "../../images/list-arrow.svg"
import BreakpointUp from "../../components/Media/BreakpointUp"
import DefaultButton from "../../components/button/DefaultButton"
import DefaultOutlineButton from "../../components/button/DefaultOutlineButton"
import BreakpointDown from "../../components/Media/BreakpointDown"

const CardFinance = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
`
const CardFigure = styled(Link)`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 1%,
      rgba(2, 3, 4, 1) 99%,
      rgba(2, 3, 4, 1) 100%
    );
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 1%,
      rgba(2, 3, 4, 1) 99%,
      rgba(2, 3, 4, 1) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 1%,
      rgba(2, 3, 4, 1) 99%,
      rgba(2, 3, 4, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#020304',GradientType=0 );
  }
`
const CardCaption = styled.div`
  font-family: "IBM Plex Serif", serif;
  font-weight: 700;
  color: #fff;
  font-size: 28px;
  line-height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 15px;
  ${BreakpointUp.md`
		padding: 20px 30px;
	`}
`

const ContentGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	${BreakpointUp.lg`		
		padding:85px 30px 55px 30px;
	`}
`
const ContentLeft = styled.div`
		width:100%;
		text-align:left;
		${BreakpointUp.lg`				
			padding:0 30px;
			flex: 0 0 50%;
			max-width: 50%;
		`}
		@media (min-width:1200px) {
			padding:0 60px;
		}
		@media (min-width:1600px) {
			padding:0 90px;
		}
`
const ContentRight = styled.div`
	width:100%;
	text-align:left;
	position:relative;
	${BreakpointUp.lg`
		padding:0 30px;
		flex: 0 0 50%;
		max-width: 50%;
		&:before{
			content: '';
			display: block;
			height: 100%;
			width: 1px;
			background-color: #ddd;
			position: absolute;
			left: -1px;
			top: 0;	
		}
	`}	
	@media (min-width:1200px) {
		padding:0 60px;
	}
	@media (min-width:1600px) {
		padding:0 90px;
	}
    
`
const FinancePoint = styled.ul`
  margin: 20px 0 0;
  list-style: none;
  ${BreakpointUp.lg`
		margin: 20px 0 0;
	`}
`
const FinancePointItem = styled.li`
		color:#020304;
		font-weight:700;
		margin-bottom:15px;		   
		position:relative;
		padding-left:20px;
		&:before {
			content: "";
			display: inline-block;
			width: 12px;
			position: absolute;
			left: 0;
			top: 6px;
			height: 12px;
			background-image:${props => props.bgImg};
			background-repeat: no-repeat;
		}
`
const SectionHeading = styled.div`
	display:block;
	margin-bottom:30px;
	padding:0 15px;
	${BreakpointUp.lg`
		margin-bottom:0;				
		display:inline-block;
		position: relative;
		top: 40px;
		z-index: 1;
		background: #F6F6F6;		
	`}
`
const ReadMore = styled(Link)`
  display: none;
  ${BreakpointUp.lg`
		display:block;	
		position:absolute;
		left:${props => props.left};
		right:${props => props.right};
		top:50%;
		transform: rotate(-90deg)translateY(-50%);
		z-index: 1;
		color:#020304;
		font-weight:700;
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			display: block;
			height: 2px;
			transition: -webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transition: transform .4s cubic-bezier(.77,0,.175,1);
			transition: transform .4s cubic-bezier(.77,0,.175,1),-webkit-transform .4s cubic-bezier(.77,0,.175,1);        
			transform: translateZ(0) scaleX(1);
			background: #020304;
			transform-origin: right center;    
			transform: translateZ(0) scaleX(0);        
		}
		& .text{
			+ & svg{
			margin-left:5px;
			}
		}
		& svg{
			fill:#020304;
			transition: transform 0.3s ease-out;
		}
		&:hover{
			color:#020304;
			&:after {
				transform-origin: left center;        
				transform: translateZ(0) scaleX(1);
			}  
			svg{
				fill:#020304;
				transform: translateX(4px);
			}			
		}						
			
	`}
`
const ButtonToolbar = styled.div`
	margin:0 -5px 30px -5px; 	
	a{
		padding:0;
		display:block;
		${BreakpointUp.sm`
			display:inline-block;
		`}
		+ a{
			margin-top:10px;
			${BreakpointUp.sm`
				margin-top:0;
			`}
		}
		${BreakpointUp.sm`
			padding:0 5px;
			margin:0;
		`}
		.btn{
			display:block;
			${BreakpointDown.xl`
				padding:10px 15px;
			`}
			${BreakpointUp.sm`
				display:inline-block;
			`}
		}
	}		
`

const FinanceSection = ({ sectionData }) => {
  return (
    <Section
      pt="90px"
      pb="90px"
      xpt="60px"
      xpb="0"
      bg="#F6F6F6"
      textAlign="center"
    >
      <ReadMore to={sectionData[0].sectionButtonLink} left="-70px">
        <span className="text">{sectionData[0].sectionButtonText}</span>
        <ArrowIcon />
      </ReadMore>
      <ReadMore to={sectionData[1].sectionButtonLink} right="-70px">
        <span className="text">{sectionData[1].sectionButtonText}</span>
        <ArrowIcon />
      </ReadMore>
      <div className="container">
        <SectionHeading>
          <SectionTitle mb="0" maxWidth="500px">
            {sectionData[0].sectionTitle}
          </SectionTitle>
        </SectionHeading>
        <ContentGrid>
          <ContentLeft>
            <CardFinance className="card">
              <CardFigure to="/" className="card-img">
                <Img fluid={sectionData[0].sectionImages[0].fluid} />
                <CardCaption>{sectionData[0].subTitle}</CardCaption>
              </CardFigure>
            </CardFinance>
            <p>{sectionData[0].sectionDescription.sectionDescription}</p>
            <FinancePoint>
              {sectionData[0].features.map((item, i) => {
                return (
                  <FinancePointItem key={i} bgImg={`url(${ListArrow})`}>
                    {item.content}
                  </FinancePointItem>
                )
              })}
            </FinancePoint>
            <ButtonToolbar>
				<a href='https://allegrologin.com/app/532e5d3e' target="_blank" rel="noreferrer"><DefaultButton text="Apply with Allegro" /></a>							
				<a href='https://www.rtonational.com/checkout/installment/customer/new?link=73965c59-4977-4d26-b634-b0f2f425b530' target="_blank" rel="noreferrer" ><DefaultOutlineButton text="Apply with RTO National" /></a>							
			</ButtonToolbar>	
          </ContentLeft>
          <ContentRight>
            <CardFinance className="card">
              <CardFigure to="/" className="card-img">
                <Img fluid={sectionData[1].sectionImages[0].fluid} />
                <CardCaption>{sectionData[1].subTitle}</CardCaption>
              </CardFigure>
            </CardFinance>
            <p>{sectionData[1].sectionDescription.sectionDescription}</p>
            <FinancePoint>
              {sectionData[1].features.map((item, i) => {
                return (
                  <FinancePointItem key={i} bgImg={`url(${ListArrow})`}>
                    {item.content}
                  </FinancePointItem>
                )
              })}
            </FinancePoint>
            <ButtonToolbar>
				<a href='https://ezpaybuildings.net/DealerContractForm.aspx?d=1208' target="_blank"><DefaultButton text="Apply with Ez Pay" /></a>							
				<a href='https://www.rtonational.com/checkout/contract/customer/new?link=2d4f5919-13c2-46ae-9961-91e45b2d4c29' target="_blank"><DefaultOutlineButton text="Apply with RTO National" /></a>							
			</ButtonToolbar>
          </ContentRight>
        </ContentGrid>
      </div>
    </Section>
  )
}

export default FinanceSection
