import React from "react"
import Img from 'gatsby-image'
import styled from 'styled-components'
// import { Link } from "gatsby"
// import ArrowIcon from "../../components/Icons/ArrowIcon"
// import DefaultOutlineButton from "../../components/button/DefaultOutlineButton"
import BreakpointDown from "../../components/Media/BreakpointDown"

const MobileHero = styled.section`
	display: none;
	position: relative;
	background-image:${props => props.bg};
	background-position: top center;
	background-size: cover;	
	z-index: 10;
	height:576px;
	
	&:after{
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: linear-gradient(to bottom,rgba(255,255,255,0.01),#030504);
		height: 50%;
	}
	${BreakpointDown.md`
		display:block;
	`}
`
const ImageCaption = styled.div`
	position: absolute;
	z-index: 10;
	color: white;
	left: 0;
	right: 0;
	width: 100%;
	margin: 0 auto;
	text-align: center;	
	padding:0 15px;
	bottom:30px;
	& h1 {
		color:#fff;
		font-size: 24px;
		line-height: 30px;
		margin-bottom:20px;
	}
	
`
// const ButtonToolbar = styled.div`	
// 	display:inline-block;
// 	& .btn{
// 		display: flex;
// 		font-size: 16px;
// 		flex-direction: row-reverse;
// 		padding: 18px 40px;
// 		.text{
// 			margin-left:0;
// 			margin-right:10px;
// 		}
// 	}
// `
const MobileBg = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	.gatsby-image-wrapper {
		width: 100%;
		height: 100%;
	}
`

const HeroMobile = ({ sectionData }) => {
	return(
		<MobileHero>
			<MobileBg>
				<Img fluid={sectionData.mobileImage.fluid} alt="mobile-hero" />
			</MobileBg>		
			<ImageCaption>
				<h1>{sectionData.sectionTitle} {sectionData.subTitle}</h1>				
				{/* <ButtonToolbar>
					<Link to={sectionData.sectionButtonLink}>
						<DefaultOutlineButton 
							text={sectionData.sectionButtonText} 
							icon={<ArrowIcon />}  
						/>
					</Link>
				</ButtonToolbar> */}
			</ImageCaption>		
		</MobileHero>
	)
}


export default HeroMobile