import React from "react"
import { Section, SectionTitle } from "../../components/section"
import FeaturedProductCarousel from '../../components/FeaturedProductCarousel'
import QuotePopup from '../../components/QuotePopup'

class CategoryLandingProducts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisibleQuote: false,
    }
    this.showQuote = this.showQuote.bind(this)
    this.onClosePopup = this.onClosePopup.bind(this)
  }
  showQuote() {
    this.setState({
      isVisibleQuote: true,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.add('popup-open')
    }
  }
  onClosePopup() {
    this.setState({
      isVisibleQuote: false,
    })
    if (typeof window !== `undefined`) {
      document.body.classList.remove('popup-open')
    }
  }
  render() {
    const { isVisibleQuote } = this.state
    const { data, location } = this.props

    return (
      <>
        <Section pt="90px" pb="90px" xpt="60px" xpb="0" bg="#F6F6F6" textAlign="center">
          <SectionTitle mb="50px" maxWidth="500px">Featured Products</SectionTitle>
          <FeaturedProductCarousel data={data} showQuote={this.showQuote} />
        </Section>
        <QuotePopup
          isVisible={isVisibleQuote}
          onClose={this.onClosePopup}
          location={location}
        />
      </>
    )
  }
}

export default CategoryLandingProducts