import React from "react"
import styled from "styled-components"
import { v4 } from "uuid"
import { Section, SectionTitle, SectionDescription } from "../../components/section"
import CategoryCard from "../../components/CategoryCard"
import BreakpointUp from "../../components/Media/BreakpointUp"
import ProductForm from "../Product/ProductForm"

const SectionGrid = styled.div`
	position: relative;
	display: flex;
	/* align-items: center; */
	flex-wrap: wrap;
	margin-left:-15px;
	margin-right:-15px;
`
const CategoryLeft = styled.div`
	padding:0 15px;
	width:100%;
	${BreakpointUp.lg`
		flex: 0 0 66.666667%;
	`}
`
const CategoryRight = styled.div`
	padding:0 15px;
	width:100%;
	${BreakpointUp.lg`
		flex: 0 0 33.333333%;	
		& .card{
			flex-direction: column;
		}
		& .card-body{
			width:100%;
			padding:50px 50px 0 50px;
		}	
	
		& .card-img{
			width:100%;
			height: 320px;
			& .gatsby-image-wrapper{
				height:100%;
			}
			&:after {
				top: 0;
				left: 0;
				width: 100%;
				height:25%;
				background: -moz-linear-gradient(top,  rgba(246,246,246,1) 0%, rgba(246,246,246,0.7) 50%,  rgba(246,246,246,0) 100%);
				background: -webkit-linear-gradient(top,  rgba(246,246,246,1) 0%, rgba(246,246,246,0.7) 50%, rgba(246,246,246,0) 100%);
				background: linear-gradient(to bottom,  rgba(246,246,246,1) 0%, rgba(246,246,246,0.7) 50%, rgba(246,246,246,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F6F6F6', endColorstr='#00fffced',GradientType=1 );
			}
		}
		.isSticky {
			max-width: 100%;
			width: 100%;
			position: sticky;
			top: 140px;
			z-index: 1;
		}
	`}
	.card-form{
		background-color: #020304;
		box-shadow: 0 0 15px rgba(0,0,0,0.3);
		border: 2px solid #fff;
		form{margin-bottom:0;}
		.card-body {
			@media (min-width: 992px){
				padding: 50px;
			}
		}
		.form-group-bordered {
			.form-control{
				color:#fff;
				&:focus, &:active{
					border-bottom-color: #ddd;
				}
				/* Change the white to any color */
				&:-webkit-autofill,
				&:-webkit-autofill:hover, 
				&:-webkit-autofill:focus, 
				&:-webkit-autofill:active{
					-webkit-text-fill-color: #fff;
				}
			}
		}		
		.form-control{
			color:#fff;			
		}
		.card-title{
			color:#fff;
		}
		.form-action{
			.btn{
				border-color:#fff;
			}
		}
	}

	
`
const CategorySection = ({sectionData, categories, location}) => {
	return(
		<Section pt="70px" pb="70px" mpb="30px">
			<div className="container">
				<SectionTitle maxWidth="490px">{sectionData.sectionTitle}</SectionTitle>
				<SectionDescription>{sectionData.sectionDescription.sectionDescription}</SectionDescription>				
				<SectionGrid>
					<CategoryLeft>
						{
							categories.edges.map((item, i)=>{
								return(
									<CategoryCard
										key={v4()}
										data={item.node} 
									/>
								)
							})
						}
					</CategoryLeft>

					<CategoryRight>
						<div className="isSticky">
							<ProductForm location={location} />
						</div>
					</CategoryRight>
				</SectionGrid>		
			</div>
		</Section>
	)
}


export default CategorySection