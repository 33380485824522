import React from "react"
import styled from 'styled-components'
import Img from 'gatsby-image'
// import { Link } from "gatsby"
// import ArrowIcon from "../../components/Icons/ArrowIcon"
// import DefaultOutlineButton from "../../components/button/DefaultOutlineButton"
// import BreakpointUp from "../../components/Media/BreakpointUp"
import BreakpointDown from "../../components/Media/BreakpointDown"
import RequestForm from "../../components/RequestForm"

const SectionHero = styled.section`	
	display:block;
	padding-top: ${props=>props.pt};
	padding-bottom: ${props=>props.pb};	
	position:relative;
	${BreakpointDown.md`
		display:none;
	`}
	${BreakpointDown.xl`
		padding-top: ${props=>props.xpt};
		padding-bottom: ${props=>props.xpb};
	`}
`
const ImageCaption = styled.div`
	position: absolute;	
	z-index: 10;
	color: white;
	bottom: 0px;
	left: 0;
	right: 0;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-around;
	padding-bottom: 50px;
	text-align: center;
	padding-top: 50px;
	margin: 0 auto;
	background: linear-gradient(to bottom,rgba(255,255,255,0.01),#030504);
	${BreakpointDown.md`
		bottom:58px;
		padding-bottom:0;
	`}
	& h1 {
		font-size:38px;
		line-height:54px;
		color:#fff;
		margin-bottom:20px;
		${BreakpointDown.xl`
		font-size:30px;
		line-height:40px;
		`}
		${BreakpointDown.lg`
			font-size:24px;
			line-height:30px;
		`}
		${BreakpointDown.sm`
			display:none;
		`}

	}
	
`
const ImageSubTitle = styled.div`	
	display:block;
	font-size:38px;
	line-height:54px;
	font-family: 'IBM Plex Serif', serif;
	font-weight: 700;
	${BreakpointDown.xl`
		font-size:30px;
		line-height:40px;
	`}
	${BreakpointDown.lg`
		font-size:24px;
		line-height:30px;
	`}
	${BreakpointDown.sm`
		display:none;
	`}
	
`
// const ButtonToolbar = styled.div`	
// 	display:inline-block;
// 	& .btn{
// 		display: flex;
// 		font-size: 16px;
// 		flex-direction: row-reverse;
// 		${BreakpointUp.xl`
// 			padding: 18px 40px;
// 		`}
// 		& svg{
// 			transition: transform 0.5s ease-out;
// 		}
// 		& .text{
// 			margin-left:0;
// 			margin-right:10px;
// 		}
// 		&:hover{
// 			& svg{
// 				transform: translateX(6px);
// 			}
// 		}
// 	}
// `
const ImageSlider = styled.div`	
	.gatsby-image-wrapper {
		height: calc(100vh - 100px);
	}
`

const CardWrap = styled.div`
	margin:0;
`
const CardHorizontal = styled.div`
	display:flex;
	flex-wrap:wrap;
	background: #fff;
	border:1px solid #ddd;
	width:100%;
    position: relative;	
    z-index: 10;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
	margin-top: -30px;
	margin-bottom:30px;
	@media(min-width:768px){
		margin-top: -50px;
		margin-bottom:60px;
	}
`
const CardBody = styled.div`
	padding:30px;
	width:100%;
	form{
		display:flex;
		flex-wrap:wrap;		
		@media(min-width:768px){
			margin:0 -20px;
		}
	}
	.form-group-bordered{
		position: relative;
		width: 100%;
		textarea.form-control{
			height:42px;
		}
		@media(min-width:768px){
			padding:0 20px;
			flex:0 0 50%;
			max-width: 50%;
			.control-label{
				left:20px;
			}
		}
	}
	.form-action{
		position: relative;
		width: 100%;
		@media(min-width:768px){
			padding:0 20px;
		}
	}
	
	& .form-action{
	& .btn{
		max-width:200px;
		width:100%;
		}
	}
`
const CardTitle = styled.div`
  font-size:24px;
  line-height:32px;
  color:#020304;
  margin-bottom:30px;  
  font-weight: 400;
`


const HeroSection = ({ sectionData, location }) => {
	return(
		<>
		<SectionHero pt="110px" xpt="60px" pb="0" className="hero">
			<ImageSlider>
				<Img fluid={sectionData.sectionImages[0].fluid} />
				<ImageCaption>
					<div><ImageSubTitle>{sectionData.sectionTitle}</ImageSubTitle><h1>{sectionData.subTitle}</h1></div>
					{/* <ButtonToolbar><Link to={sectionData.sectionButtonLink}><DefaultOutlineButton text={sectionData.sectionButtonText}  icon={<ArrowIcon />}  /></Link></ButtonToolbar> */}
				</ImageCaption>
			</ImageSlider>
		</SectionHero>
		<CardWrap>
			<div className="container">
				<CardHorizontal className='card-form'>
					<CardBody className='card-body'>
						<CardTitle className='card-title h2'>Let's Get Started. Fill out this quick form.</CardTitle>
						<RequestForm location={location} />
					</CardBody>
				</CardHorizontal>
			</div>
		</CardWrap>
		</>
	)
}

export default HeroSection
